import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import useAuth from "../../../hooks/useAuth";
import Preloader from "../../common/Preloader";
import { getInvitationTargets } from "../../../api/Invitations/getInvitationTargets";
import { fieldRequired, fieldWithIdRequired } from "../../../utils/helpers";
import { getInvitationByCode } from "../../../api/Invitations/getInvitationByCode";
import FileInput from "../../common/FileInput";

const RegisterBuyer = ({invitationUniqueCode}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [ownersLastName, setOwnersLastName] = useState('');
  const [ownersFirstName, setOwnersFirstName] = useState('');
  const [ownersOtherNames, setOwnersOtherNames] = useState('');
  const [ownersHomeAddress, setOwnersHomeAddress] = useState('');
  const [logo, setLogo] = useState('');
  const [startingYearOfBusiness, setStartingYearOfBusiness] = useState('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState('');
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [certifications, setCertifications] = useState('');
  const [insuranceInformation, setInsuranceInformation] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [socialMediaLinks, setSocialMediaLinks] = useState('');
  const [invitationId, setInvitationId] = useState('');

  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  const getSetUpData = async () => {
    try {
      setShowPreloader(true);
      const invitation = await getInvitationByCode(invitationUniqueCode);

      if(!invitation) {
        toast.warn("The registration token submitted is invalid!");
        navigate('/');
        return;
      } else {
        //Set default fields
        setInvitationId(invitation.id);
        setEmail(invitation.email);
        setMobileNumber(invitation.telephone);

        //Make sure the right registration is ensured
        const theTarget = invitation.target.toUpperCase();
        if(theTarget !== "BUYER") {
          toast.warn("Invalid registration type!");
          navigate('/');
          return;
        }
      }
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const validateFields = () => {
    let retVal = true;
    if (!businessName) {
      fieldWithIdRequired('businessName');
      toast.warn("Business Name is required.");
      retVal = false;
    }
    if (!email) {
      fieldWithIdRequired('email');
      toast.warn("Email is required.");
      retVal = false;
    }
    if (!mobileNumber) {
      fieldWithIdRequired('mobileNumber');
      toast.warn("Mobile Number is required.");
      retVal = false;
    }
    if (!businessAddress) {
      fieldWithIdRequired('businessAddress');
      toast.warn("Business Address is required.");
      retVal = false;
    }
    if (!ownersLastName) {
      fieldWithIdRequired('ownersLastName');
      toast.warn("Owner's Last Name is required.");
      retVal = false;
    }
    if (!ownersFirstName) {
      fieldWithIdRequired('ownersFirstName');
      toast.warn("Owner's First Name is required.");
      retVal = false;
    }
    if (!ownersHomeAddress) {
      fieldWithIdRequired('ownersHomeAddress');
      toast.warn("Owner's Home Address is required.");
      retVal = false;
    }
    if (!startingYearOfBusiness) {
      fieldWithIdRequired('startingYearOfFarming');
      toast.warn("Starting Year of Farming is required.");
      retVal = false;
    }
    if (!invitationId) {
      toast.warn("The Invitation information is missing.");
      retVal = false;
    }
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    const data = {
      businessName,
      email,
      mobileNumber,
      businessAddress,
      ownersLastName,
      ownersFirstName,
      ownersOtherNames,
      ownersHomeAddress,
      startingYearOfBusiness,
      logo,
      businessRegistrationNumber,
      taxIdentificationNumber,
      website,
      certifications,
      insuranceInformation,
      yearsOfExperience,
      businessDescription,
      socialMediaLinks,
      invitationId,
      clientBaseUrl : window.location.origin
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/buyers/register`, requestOptions);

      if (response.ok) {
        toast.success("Your registration as a Buyer is complete.");
        navigate("/");
        setShowPreloader(false);
      } else {
        await handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Could not complete the registration!");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Register as a Buyer</div>
              {/* <div className="card-sub-title">Enter your details below and submit when you are done.</div> */}
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="businessName">Business Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="businessName"
                      value={businessName}
                      onChange={(e) => { setBusinessName(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Business Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="firstName">Email *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      value={email}
                      readOnly
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="firstName">Mobile Number *</label>
                    <input
                      type="phone"
                      className="form-control"
                      id="mobileNumber"
                      value={mobileNumber}
                      readOnly
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="firstName">Business Address *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="businessAddress"
                      value={businessAddress}
                      onChange={(e) => { setBusinessAddress(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Business Address..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="ownersLastName">Owner's Last Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ownersLastName"
                      value={ownersLastName}
                      onChange={(e) => { setOwnersLastName(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Owner's Last Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="ownersFirstName">Owners's First Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ownersFirstName"
                      value={ownersFirstName}
                      onChange={(e) => { setOwnersFirstName(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the First Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="ownersOtherNames">Owner's Other Names</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ownersOtherNames"
                      value={ownersOtherNames}
                      onChange={(e) => { setOwnersOtherNames(e.target.value); }}
                      placeholder="Enter the Owner's Other Names..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="ownersHomeAddress">Owner's Home Address *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ownersHomeAddress"
                      value={ownersHomeAddress}
                      onChange={(e) => { setOwnersHomeAddress(e.target.value) ; fieldRequired(e); }}
                      placeholder="Enter the Owner's Home Address..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="startingYearOfBusiness">Starting Year of Business *</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="startingYearOfBusiness"
                      value={startingYearOfBusiness}
                      onChange={(e) => { setStartingYearOfBusiness(e.target.value); fieldRequired(e);}}
                      placeholder="Enter the Starting Year of Business..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="logo">Business Logo</label>
                    <FileInput
                      id={"logo"}
                      value={logo}
                      setValue={setLogo}
                      required={false}
                      className ="form-control"
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="businessRegistrationNumber">Business Registration Number</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="businessRegistrationNumber"
                      value={businessRegistrationNumber}
                      onChange={(e) => { setBusinessRegistrationNumber(e.target.value); }}
                      placeholder="Enter the Business Registration Number..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="taxIdentificationNumber">Tax Identification Number</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="taxIdentificationNumber"
                      value={taxIdentificationNumber}
                      onChange={(e) => { setTaxIdentificationNumber(e.target.value); }}
                      placeholder="Enter the Tax Identification Number..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="website">Website</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="website"
                      value={website}
                      onChange={(e) => { setWebsite(e.target.value); }}
                      placeholder="Enter Website..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="certifications">Certifications</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="certifications"
                      value={certifications}
                      onChange={(e) => { setCertifications(e.target.value); }}
                      placeholder="Enter the Certifications..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="insuranceInformation">Insurance Information</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="insuranceInformation"
                      value={insuranceInformation}
                      onChange={(e) => { setInsuranceInformation(e.target.value); }}
                      placeholder="Enter Insurance Information..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="yearsOfExperience">Years of Experience</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="yearsOfExperience"
                      value={yearsOfExperience}
                      onChange={(e) => { setYearsOfExperience(e.target.value); }}
                      placeholder="Enter your Years of Experience..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="businessDescription">Business Description</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="businessDescription"
                      value={businessDescription}
                      onChange={(e) => { setBusinessDescription(e.target.value); }}
                      placeholder="Enter Business Description..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="socialMediaLinks">Social Media Links</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="socialMediaLinks"
                      value={socialMediaLinks}
                      onChange={(e) => { setSocialMediaLinks(e.target.value); }}
                      placeholder="Enter your Social Media Links..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <div className="float-end">
                    <button type="button" onClick={handleSubmitCreate} className="btn btn-primary float-end">
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default RegisterBuyer;
