import { useEffect, useState } from "react";
import { getAdminDashActivities } from "../../api/Dashboard/getAdminDashActivities";
import useAuth from "../../hooks/useAuth";
import { formatAndInterpretDate } from "../../utils/helpers";

const AdminDashActivities = () => {
    const { user } = useAuth();
    const [adminDashActivities, setAdminDashActivities] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const fetchAdminActivities = async () => {
        setLoadingData(true);
        try {
            const data = await getAdminDashActivities(user.user.token);
            setAdminDashActivities(data);
        } catch (error) {
            console.error("Error fetching admin activities:", error);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        // Initial data fetch
        fetchAdminActivities();

        // Set up interval for refreshing data every 5 minutes
        const intervalId = setInterval(fetchAdminActivities, 300000); // 5 minutes = 300,000 ms

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [user]);

    return (
        <div className="card dashboard-card" style={{ minHeight: "357px" }}>
            <div className="card-header">
                <div className="card-title">Activities</div>
            </div>
            <div className="card-body">
                {loadingData && (
                    <div>
                        <em>Loading...</em>
                    </div>
                )}
                {!loadingData && (
                    <>
                        {adminDashActivities && adminDashActivities.length > 0 ? (
                            <div className="customScroll5">
                                <ul className="project-activity">
                                    {adminDashActivities.map((activity, index) => (
                                        <li key={index} className="activity-list">
                                            <div className="detail-info">
                                                <p className="date">
                                                    {formatAndInterpretDate(activity.day)}
                                                </p>
                                                {activity.events &&
                                                    activity.events.map((evnt, evntIndex) => (
                                                        <p
                                                            key={`evnt-${evntIndex}`}
                                                            className="info"
                                                        >
                                                            {evnt}
                                                        </p>
                                                    ))}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <em>No activity detected in the last 7 days.</em>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default AdminDashActivities;
