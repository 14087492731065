import { Navigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getNutrientIngredientQuantityUnit, getNutrientMaturityStagesOfCrop, getNutrientSources } from "../../api/NutrientManagementRecords/getNutrientRecordsList";
import { getLifespanUnits } from "../../api/miscellaneous/getQuantityUnits";
import { getInductionPurposes } from "../../api/InductionRecords/getInductionList";
import EnvironmentalCondition from "../common/EnvironmentalCondition";
import { getInductionProductsUsed } from "../../api/InductionRecords/getInductionProductsUsed";
import { CompanyIds, runningCompanyId } from "../../appSetup";
import { getEquipmentUsedList } from "../../api/PackHouseRecords/getPackHouseRecordsList";
import Select from "react-select";
import CropInfo from "../Crop/CropInfo";
 
const InductionForm = ({ inductionRecords,crop }) => {
  const { user } = useAuth();

  const [date, setDate] = useState('');
  const [activeIngredients, setActiveIngredients] = useState('');
  const [appliedBy, setAppliedBy] = useState('');
  const [source, setSource] = useState('');
  const [supervisedBy, setSupervisedBy] = useState('');
  const [nameOfProduct, setNameOfProduct] = useState('');
  const [brixOfFruitAtSpray, setBrixOfFruitAtSpray] = useState('');
  const [dateOfHarvest, setDateOfHarvest] = useState('');
  const [expectedDateOfMaturity, setExpectedDateOfMaturity] = useState('');
  const [ageOfFruit, setAgeOfFruit] = useState('');
  const [purpose, setPurpose] = useState('');
  const [quantityApplied, setQuantityApplied] = useState('');
  const [quantityAppliedUnit, setQuantityAppliedUnit] = useState('');
  const [equipmentsUsed, setEquipmentsUsed] = useState([]);

  const [code, setCode] = useState('');

  const [ageOfPlant, setAgeOfPlant] = useState('');
  const [ageOfPlantUnit, setAgeOfPlantUnit] = useState('');

  const [associatedCost, setAssociatedCost] = useState('');

  const [inductionRecordId, setInductionRecordId] = useState(null);

  const [ingredientQuantityUnitList, setIngredientQuantityUnitList] = useState([]);
  const [ageOfPlantUnitList, setAgeOfPlantUnitList] = useState([]);
  const [sourceList, setsourceList] = useState([]);
  const [purposeList, setPurposeList] = useState([]);
  const [productsUsedList, setProductsUsedList] = useState([]);
  const [equipmentUsedList, setEquipmentUsedList] = useState([]);

    //env conditions data
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');
  const [environmentalCondition, setEnvironmentalCondition] = useState();

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);


  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleEyeIconClick = () => {
    if (crop) {
      setModalData({ crop });
      setShowModal(true);
    } else {
      toast.warn("No crop data available to display.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };
  useEffect(() => {
    getSetUpData();
    if(runningCompanyId === CompanyIds.TropighaFarmsLtd) {
      getInductionProductsUsed().then(m => setProductsUsedList(m));
    }
  }, []);


  useEffect(() => {

    if (inductionRecordId) {
      getInductionRecords(inductionRecordId);
    }
  }, [inductionRecordId])

  useEffect(() => {
    if (inductionRecords) {
      setInductionRecordId(inductionRecords.id);
      setDate(inductionRecords.date);
      setSource(inductionRecords.source);
      setActiveIngredients(inductionRecords.activeIngredients);
      setNameOfProduct(inductionRecords.nameOfProduct);
      setAssociatedCost(inductionRecords.associatedCost);
      setAgeOfPlant(inductionRecords.ageOfPlant);
      setAgeOfPlantUnit(inductionRecords.ageOfPlantUnit);
      setQuantityApplied(inductionRecords.quantityApplied);
      setQuantityAppliedUnit(inductionRecords.quantityAppliedUnit);
      setAppliedBy(inductionRecords.appliedBy);
      setSupervisedBy(inductionRecords.supervisedBy);
      setBrixOfFruitAtSpray(inductionRecords.brixOfFruitAtSpray);
      setDateOfHarvest(inductionRecords.dateOfHarvest);
      setExpectedDateOfMaturity(inductionRecords.expectedDateOfMaturity);
      setAgeOfFruit(inductionRecords.ageOfFruit);
      setPurpose(inductionRecords.purpose);
      setEquipmentsUsed(
        inductionRecords.equipmentsUsed
          ? inductionRecords.equipmentsUsed.map(equip => ({
              value: equip,
              label: equip
            }))
          : []
      );

      setCode(inductionRecords.code);
      // setUserId(userData.id);

      if (inductionRecords.environmentalCondition) {
        setEnvironmentalConditionId(inductionRecords.environmentalCondition.id);
        setEnvironmentalCondition(inductionRecords.environmentalCondition);
      }

    }
  }, [inductionRecords]);


  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    try {
      setShowPreloader(true);
      setPurposeList(await getInductionPurposes(user.user.token));
      setAgeOfPlantUnitList(await getLifespanUnits(user.user.token));
      setIngredientQuantityUnitList(await getNutrientIngredientQuantityUnit(user.user.token));
      setsourceList(await getNutrientSources(user.user.token));
      setEquipmentUsedList(await getEquipmentUsedList(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getInductionRecords = async () => {

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/inductionrecords/${inductionRecordId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setDate(data.date);
        setActiveIngredients(data.activeIngredients);
        setAssociatedCost(data.associatedCost);
        setAgeOfPlant(data.ageOfPlant);
        setAgeOfPlantUnit(data.ageOfPlantUnit);
        setAppliedBy(data.appliedBy);
        setSource(data.source);
        setSupervisedBy(data.supervisedBy);
        setNameOfProduct(data.nameOfProduct);
        setQuantityApplied(data.quantityApplied);
        setQuantityAppliedUnit(data.quantityAppliedUnit);
        setBrixOfFruitAtSpray(data.brixOfFruitAtSpray);
        setDateOfHarvest(data.dateOfHarvest);
        setExpectedDateOfMaturity(data.expectedDateOfMaturity);
        setAgeOfFruit(data.ageOfFruit);
        setPurpose(data.purpose);
        setEquipmentsUsed(
          data.equipmentsUsed
            ? data.equipmentsUsed.map(equip => ({
              value: equip,
              label: equip
            }))
            : []
        );

        setCode(data.code);

        if (data.environmentalCondition) {
          setEnvironmentalConditionId(data.environmentalCondition.id);
          setEnvironmentalCondition(data.environmentalCondition);
        }

        console.log("ind rec >:", data);

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching induction records data.');
        console.error("Error fetching induction records data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }
    if (!activeIngredients) {
      fieldWithIdRequired('activeIngredients');
      toast.error("Active Ingredients is required.");
      retVal = false;
    }
    if (!nameOfProduct) {
      fieldWithIdRequired('nameOfProduct');
      toast.error("Planting Material Type is required.");
      retVal = false;
    }

    if (!source) {
      fieldWithIdRequired('source');
      toast.error("Source is required.");
      retVal = false;
    }

    if (!associatedCost) {
      fieldWithIdRequired('associatedCost');
      toast.error("Associated Cost is required.");
      retVal = false;
    }

    if (!ageOfPlant) {
      fieldWithIdRequired('ageOfPlant');
      toast.error("Age Of Plant is required.");
      retVal = false;
    }

    if (!ageOfPlantUnit) {
      fieldWithIdRequired('ageOfPlantUnit');
      toast.error("Age Of Plant Unit is required.");
      retVal = false;
    }

    if (!quantityApplied) {
      fieldWithIdRequired('quantityApplied');
      toast.error("Quantity Applied is required.");
      retVal = false;
    }

    if (!quantityAppliedUnit) {
      fieldWithIdRequired('quantityAppliedUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    if (!appliedBy) {
      fieldWithIdRequired('appliedBy');
      toast.error("Applied By is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }

    

    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      cropId:crop.id,
      date: date,
      activeIngredients: activeIngredients,
      associatedCost: associatedCost,
      ageOfPlant: ageOfPlant,
      ageOfPlantUnit: ageOfPlantUnit,
      supervisedBy: supervisedBy,
      appliedBy: appliedBy,
      nameOfProduct: nameOfProduct,
      equipmentsUsed: equipmentsUsed.map(option => option.value),
      source:source,
      quantityApplied: quantityApplied,
      quantityAppliedUnit:quantityAppliedUnit,
      purpose:purpose,
      ageOfFruit:ageOfFruit,
      expectedDateOfMaturity:expectedDateOfMaturity,
      dateOfHarvest:dateOfHarvest,
      brixOfFruitAtSpray:brixOfFruitAtSpray,
      environmentalCondition,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/inductionrecords`, requestOptions);

      if (response.ok) {
        toast.success("The Induction Record has been successfully created.");
        console.log("post success response >>:", response);
        setShowPreloader(true);
        navigate(`/inductionrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the inductionrecords.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: inductionRecordId,
      cropId:crop.id,
      date: date,
      activeIngredients: activeIngredients,
      associatedCost: associatedCost,
      ageOfPlant: ageOfPlant,
      ageOfPlantUnit: ageOfPlantUnit,
      supervisedBy: supervisedBy,
      appliedBy: appliedBy,
      nameOfProduct: nameOfProduct,
      source:source,
      quantityApplied: quantityApplied,
      quantityAppliedUnit:quantityAppliedUnit,
      equipmentsUsed: equipmentsUsed.map(option => option.value),
      purpose:purpose,
      ageOfFruit:ageOfFruit,
      expectedDateOfMaturity:expectedDateOfMaturity,
      dateOfHarvest:dateOfHarvest,
      brixOfFruitAtSpray:brixOfFruitAtSpray,
      environmentalConditionId,
      environmentalCondition,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/inductionrecords/${inductionRecordId}`, requestOptions);

      if (response.ok) {
        toast.success("The induction record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/inductionrecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the induction record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const equipmentOptions = equipmentUsedList.map(equip => ({
    value: equip,
    label: equip
  }));

  const handleChange = (selectedOptions) => {
    setEquipmentsUsed(selectedOptions || []);
  };


  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Induction Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/inductionrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                  <span> {crop && (
                                            <i className="icon-eye"
                                            onClick={handleEyeIconClick}
                                            style={{ cursor: "pointer" }} />)}</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">
                { inductionRecordId && (
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={code}
                        readOnly
                      />
                      <div className="invalid-feedback">This is required</div>
                    </div>
                  </div>
                )}
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      value={isoToDate(date)}
                      onChange={(e) => {setDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                {runningCompanyId !== CompanyIds.TropighaFarmsLtd &&
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="nameOfProduct">Name Of Product *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nameOfProduct"
                        placeholder="Enter the name of Product..."
                        value={nameOfProduct}
                        onChange={(e) => {setNameOfProduct(e.target.value); fieldRequired(e); }}
                      />
                      <div className="invalid-feedback">This is required</div>
                    </div>
                  </div>
                }
                {runningCompanyId === CompanyIds.TropighaFarmsLtd &&
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="nameOfProduct">Name Of Product *</label>
                      <select
                        className="form-control form-control-lg"
                        id="nameOfProduct"
                        value={nameOfProduct}
                        onChange={(e) => { setNameOfProduct(e.target.value); fieldRequired(e); }}
                      >
                        <option value="">Select the Product...</option>
                        {productsUsedList && productsUsedList.map((product) => (
                          <option key={product} value={product}>
                            {product}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">This is required</div>
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Equipment Used</label>
                    <Select
                      id="equipmentSelection"
                      options={equipmentOptions}
                      onChange={handleChange}
                      placeholder="Select Equipment..."
                      isClearable
                      isMulti
                      value={equipmentsUsed}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Purpose</label>
                    <select
                      className="form-control form-control-lg"
                      id="purpose"
                      value={purpose}
                      onChange={(e) => { setPurpose(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Purpose...</option>
                      {purposeList && purposeList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Active Ingredients *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="expectedYield"
                      placeholder="Enter the active ingredients..."
                      value={activeIngredients}
                      onChange={(e) => {setActiveIngredients(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="ageOfPlant">Age of Plant *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="ageOfPlant"
                      placeholder="Enter the age of plant..."
                      value={ageOfPlant}
                      onChange={(e) => {setAgeOfPlant(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="ageOfPlantUnit">Age Of Plant Unit *</label>
                    <select
                      className="form-control form-control-lg"
                      id="ageOfPlantUnit"
                      value={ageOfPlantUnit}
                      onChange={(e) => { setAgeOfPlantUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Age Of Plant Unit...</option>
                      {ageOfPlantUnitList && ageOfPlantUnitList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="ageOfFruit">Age Of Fruit *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ageOfFruit"
                      placeholder="Enter the age of fruit..."
                      value={ageOfFruit}
                      onChange={(e) => {setAgeOfFruit(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                {runningCompanyId !== CompanyIds.TropighaFarmsLtd &&
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="expectedDateOfMaturity">Expected Date Of Maturity</label>
                      <input
                        type="date"
                        className="form-control"
                        id="expectedDateOfMaturity"
                        value={expectedDateOfMaturity}
                        onChange={(e) => {setExpectedDateOfMaturity(e.target.value); fieldRequired(e); }}
                      />
                      <div className="invalid-feedback">This is required</div>
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="dateOfHarvest">Expected Date Of Harvest</label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateOfHarvest"
                      value={isoToDate(dateOfHarvest)}
                      onChange={(e) => {setDateOfHarvest(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="brixOfFruitAtSpray">Brix Of Fruit At Spray</label>
                    <input
                      type="text"
                      className="form-control"
                      id="brixOfFruitAtSpray"
                      placeholder="Enter the brix of fruit at spray..."
                      value={brixOfFruitAtSpray}
                      onChange={(e) => {setBrixOfFruitAtSpray(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityApplied">Quantity Applied *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="expectedYield"
                      placeholder="Enter the quantity applied..."
                      value={quantityApplied}
                      onChange={(e) => {setQuantityApplied(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUnit">Quantity Applied Unit *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={quantityAppliedUnit}
                      onChange={(e) => { setQuantityAppliedUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Ingredient Applied Type...</option>
                      {ingredientQuantityUnitList && ingredientQuantityUnitList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="source">Source *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={source}
                      onChange={(e) => { setSource(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Source Type...</option>
                      {sourceList && sourceList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="associatedCost">Associated Cost <em>(cedis)</em> *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="associatedCost"
                      placeholder="Enter the associated cost..."
                      value={associatedCost}
                      onChange={(e) => {setAssociatedCost(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="appliedBy">Applied By *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="appliedBy"
                      placeholder="Enter the applied by..."
                      value={appliedBy}
                      onChange={(e) => {setAppliedBy(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Supervised By</label>
                    <input
                      type="text"
                      className="form-control"
                      id="supervisedBy"
                      placeholder="Enter the supervised by..."
                      value={supervisedBy}
                      onChange={(e) => setSupervisedBy(e.target.value)}
                    />
                  </div>
                </div>

                

              </div>
              
              <EnvironmentalCondition environmentalCondition={environmentalCondition} setEnvironmentalCondition={setEnvironmentalCondition} />

            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(inductionRecordId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
      {showModal && modalData && (
                <div
                    className="modal fade show"
                    style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="cropInfoModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="cropInfoModalLabel">Crop Information</h5>
                                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <CropInfo contract={modalData} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </>
  );
}

export default InductionForm;