// import { ENV, testDataUrl, liveDataUrl, testDataUrl_ForLive, liveDataUrl_ForLive } from "./config";
// import { notifyDefault, notifySuccess, notifyInformation, notifyWarning, notifyDanger, notifySticky } from './notification.js';

import { getUserById } from "../api/User/getUserById";
import { CompanyIds, runningCompanyId } from "../appSetup";

export function getMonthName(monthNum) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let theMonthNum = parseInt(monthNum);
  return months[theMonthNum - 1];
}


export function findMaxValueInMultipleArrays(arrays) {
  return arrays.reduce((max, arr) => Math.max(max, ...arr), -Infinity);
}

export function findMaxValueInMultipleArrays_Safer(arrays) {
  return arrays.reduce((max, arr) => {
    const arrMax = Math.max(...arr.filter(val => typeof val === 'number'));
    return Math.max(max, arrMax);
  }, -Infinity);
}


export function convertJSONToCSV(jsonData) {
  const delimiter = ','; // CSV delimiter
  
  // console.log('jsonData >>> : ', jsonData);

  // Extracting column headers from the first object in the array
  const headers = Object.keys(jsonData[0]);

  // Generating CSV rows
  const rows = jsonData.map(obj => {
    return headers.map(header => {
      let value = obj[header];

      // Handling empty values and escaping double quotes within values
      if (value === null || value === undefined) {
        value = '';
      } else {
        value = value.toString().replace(/"/g, '""'); // Escape double quotes
      }

      return `"${value}"`; // Wrap value in double quotes
    }).join(delimiter);
  });

  // Adding header row to the beginning of the CSV data
  const csvData = [headers.join(delimiter), ...rows].join('\n');

  return csvData;
}

export function downloadCSV(csvData, fileName) {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  // Clean up the object URL
  URL.revokeObjectURL(url);
}

export function getCSVData(url, fileName) {

  const headers = {
    'Content-Type': 'application/json'
  };

  fetch(url, {
    method: 'GET',
    headers: headers
  })
    .then(response => {
      if (!response.ok) {
        //notifyWarning('The request is not possible now. Contact Support.');
      }
      return response.json();
    })
    .then(data => {
      if (data !== undefined & data.statusCode === 200) {

        if(data.response.length === 0){
          //Display information to show that there is no data fetched.   
          // notifyWarning('No data is available for the selected criteria!', 'No Data for CSV Export');  
          alert('No data is available for the selected criteria!'/*, 'No Data for CSV Export'*/);        
          return;
        }

        let convertedJsonToCsv = convertJSONToCSV(data.response);
        downloadCSV(convertedJsonToCsv, fileName);
      }
      else {
        //Display information that data is not fetched successfully!
      }
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error);
      //notifyDanger('There is an error fetching data.');
      // TODO: show an error message to the user
    }).finally(() => {
    });
}

// // Example usage:
// const csvData = convertJSONToCSV(jsonData);
// const fileName = 'data.csv';
// downloadCSV(csvData, fileName);


export function stringContainsIgnoreCase(mainString, searchString) {
  const lowerMainString = mainString.toLowerCase();
  const lowerSearchString = searchString.toLowerCase();

  return lowerMainString.includes(lowerSearchString);
};

export function removeCharFromString(str, charToRemove) {
  return str.replace(new RegExp(charToRemove, 'g'), '');
}

export function shiftInsertArray(arr, searchStr, index) {
  // Shift forward following items
  for (let i = arr.length - 1; i > index; i--) {
    arr[i] = arr[i - 1];
  }

  // Insert the string at the index position
  arr[index] = searchStr;

  // Delete all other occurrences of the string in the array
  for (let i = arr.length - 1; i >= 0; i--) {
    if (i !== index && arr[i] === searchStr) {
      arr.splice(i, 1);
    }
  }

  return arr;
};


export function isoToTime(isoString) {
  const dateObject = new Date(isoString);
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function isoToDate(isoString) {
  const dateObject = new Date(isoString);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function displayDateTime(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour time

  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
}

export function isoToDateTime(isoString) {
  return isoToDate(isoString) + "T" + isoToTime(isoString);
}

export function formatAndInterpretDate(inputDate) {
  const date = new Date(inputDate);

  const isToday = (date) => {
      const today = new Date();
      return date.toDateString() === today.toDateString();
  };

  const isYesterday = (date) => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return date.toDateString() === yesterday.toDateString();
  };

  const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Handles 11th, 12th, etc.
      switch (day % 10) {
          case 1: return "st";
          case 2: return "nd";
          case 3: return "rd";
          default: return "th";
      }
  };

  if (isToday(date)) {
      return "Today";
  }

  if (isYesterday(date)) {
      return "Yesterday";
  }

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' }); // E.g., December
  const ordinalSuffix = getOrdinalSuffix(day);

  return `${day}${ordinalSuffix} ${month}`;
};


export function getFullBMRProductTypeName(productType) {
  return productType ? productType.productCode + " - " + productType.fillVolume + " - " + productType.productName : '--';
}


export function getUserFullNameWithUsername(userObject) {
  return (userObject) ? getUserFullName(userObject) + (userObject.username ? " (" + userObject.username + ")" : "") : "";
}

export function getUserFullName(userObject) {
  return (userObject) ? userObject.firstName + (userObject.otherNames ? " " + userObject.otherNames : "") + (userObject.lastName ? ", " + userObject.lastName : "") : "";
}

export function getFarmerFullName(farmer) {
  return (farmer) ? farmer.firstName + (farmer.otherNames ? " " + farmer.otherNames : "") + (farmer.lastName ? ", " + farmer.lastName : "") : "";
}

export function getUserFullNameByUserId(token, id){
  const user = Promise.resolve(getUserById(token, id));
  
  return user ? getUserFullName(user) : "--";
}

export async function getUserFullNameWithUsernameByUserId(token, id){
// return "friendly again";

  const user = await getUserById(token, id);

  return user ? getUserFullNameWithUsername(user) : "--";
}

export function getFullCropName(crop) {
  return crop ? crop.cropCode + " - " + crop.name  : '--';
}

export function getFullFieldName(field) {
  return field ? field.fieldCode + " - " + field.name  : '--';
}

export function getFullFarmName(farm) {
  return farm ? farm.farmCode + " - " + farm.name  : '--';
}

export function getFullPackagingName(packaging) {
  return packaging ? packaging.packagingCode + " - " + packaging.name  : '--';
}

export function getFullBuyerName(buyer) {
  return buyer ? buyer.code + " - " + buyer.businessName  : '--';
}

export function getFullBuyerName_NameFirst(buyer) {
  return buyer ? buyer.businessName + ' (' + buyer.code + ')' : '--';
}

export function abbreviateString(input, numWords) {
  if (!input || input.trim() === "") {
      return "--";
  }

  const words = input.trim().split(/\s+/); // Split by spaces
  const limitedWords = words.slice(0, numWords); // Take the first `numWords` words
  const capitalized = limitedWords.map(word => word[0].toUpperCase()).join("");

  return capitalized;
}

export function recordAccessAllowed(setup, canViewField) {
  if(!(setup)) return false; //If the setup record is not defined, return false.

  return setup[canViewField];
}

export async function attachActors(token, theRecords, userIdProperty, userNameProperty) {
  if(theRecords && theRecords.length > 0) {
    for(let index = 0; index < theRecords.length; index++) {
      if(index > 0 && theRecords[index][userIdProperty] === theRecords[index - 1][userIdProperty]) {
        theRecords[index][userNameProperty] = theRecords[index - 1][userNameProperty];
      }else {
        const theUser = await getUserById(token, theRecords[index][userIdProperty]);
        theRecords[index][userNameProperty] = theUser ? getUserFullName(theUser) : '--';
      }
    }
  }

  return theRecords;
}



export function fieldRequired(e) {
  const field = e.target;
  const feedback = field.nextElementSibling;
  
  if (!field.value.trim()) {
      field.classList.add('is-invalid');
      feedback.style.display = 'block';
  } else {
      field.classList.remove('is-invalid');
      feedback.style.display = 'none';
  }
};

export function fieldWithIdRequired(fieldId) {
  const field = document.getElementById(fieldId);
  if (!field) return; // Exit if the field element is not found

  const feedback = field.parentElement.querySelector('.invalid-feedback');
  if (!feedback) return; // Exit if no feedback element is found

  if (!field.value.trim()) {
      field.classList.add('is-invalid');
      feedback.style.display = 'block';
  } else {
      field.classList.remove('is-invalid');
      feedback.style.display = 'none';
  }
};

export function selectFieldWithIdRequired(fieldId, fieldValues) {
  const field = document.getElementById(fieldId);
  if (!field) return; // Exit if the field element is not found

  const feedback = field.parentElement.querySelector('.invalid-feedback');
  if (!feedback) return; // Exit if no feedback element is found

  if (!fieldValues || fieldValues.length == 0) {
      field.classList.add('is-invalid');
      feedback.style.display = 'block';
  } else {
      field.classList.remove('is-invalid');
      feedback.style.display = 'none';
  }
};

export function getFarmAreaUnit() {
  if(runningCompanyId === CompanyIds.TropighaFarmsLtd) {
    return 'hectors';
  }

  return 'acres';
}