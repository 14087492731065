import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SideBarWrapper from '../../components/common/SideBarWrapper';
import useAuth from '../../hooks/useAuth';
import { baseURL } from '../../utils/misc';
import Navbar from '../../layout/Navbar';
import Preloader from "../../components/common/Preloader";
import FileInput from '../common/FileInput';
import { Link, useNavigate } from 'react-router-dom';
import { fieldRequired, fieldWithIdRequired } from '../../utils/helpers';

function BuyerInfo() {
    const { user } = useAuth();
    const [showPreloader, setShowPreloader] = useState(false);
    
  const [email, setEmail] = useState('');
  const [gln, setGln] = useState(null);
  const [pprsdCode, setPprsdCode] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [ownersLastName, setOwnersLastName] = useState('');
  const [ownersFirstName, setOwnersFirstName] = useState('');
  const [ownersOtherNames, setOwnersOtherNames] = useState('');
  const [ownersHomeAddress, setOwnersHomeAddress] = useState('');
  const [startingYearOfBusiness, setStartingYearOfBusiness] = useState('');
  const [logo, setLogo] = useState('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState('');
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [typeOfProductsExported, setTypeOfProductsExported] = useState('');
  const [exportDestinations, setExportDestinations] = useState('');
  const [certifications, setCertifications] = useState('');
  const [insuranceInformation, setInsuranceInformation] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [socialMediaLinks, setSocialMediaLinks] = useState('');
  const [buyerInfoId, setbuyerInfoId] = useState(null); 
    // State variables for company information
    const [buyerData, setbuyerData] = useState({
        gln: '',
        pprsdCode: '',
        businessName: '',
        email: '',
        mobileNumber: '',
        businessAddress: '',
        ownersLastName: '',
        ownersFirstName: '',
        ownersOtherNames: '',
        ownersHomeAddress: '',
        startingYearOfBusiness: '',
        logo: '',
        businessRegistrationNumber: '',
        taxIdentificationNumber: '',
        website: '',
        typeOfProductsExported: '',
        exportDestinations: '',
        certifications: '',
        insuranceInformation: '',
        yearsOfExperience: '',
        businessDescription: '',
        socialMediaLinks: '',
        buyerInfoId: null,  // For existing company ID
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.user) {
            fetchCompanyInfo();
        }
    }, [user]);

    const token = user?.user?.token;
    const emailAddress = user?.user?.email;

    const fetchCompanyInfo = async () => {
        const apiUrl = `${baseURL}/buyers/email/${emailAddress}`;
        setShowPreloader(true);

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const buyerData = await response.json();
                if (buyerData) {
                    setbuyerData(prevData => ({
                        ...prevData,
                        buyerInfoId: buyerData.id,
                        ...buyerData,
                    }));
                }
            } else {
                console.error('Failed to fetch company information');
            }
        } catch (error) {
            console.error('Error occurred:', error);
        } finally {
            setShowPreloader(false);
        }
    };
    function updateStates(buyerData) {
        setGln(buyerData.gln || null);
        setPprsdCode(buyerData.pprsdCode || null);
        setBusinessName(buyerData.businessName || '');
        setEmail(buyerData.email || '');
        setTelephone(buyerData.telephone || '');
        setBusinessAddress(buyerData.businessAddress || '');
        setOwnersLastName(buyerData.ownersLastName || '');
        setOwnersFirstName(buyerData.ownersFirstName || '');
        setOwnersOtherNames(buyerData.ownersOtherNames || '');
        setOwnersHomeAddress(buyerData.ownersHomeAddress || '');
        setStartingYearOfBusiness(buyerData.startingYearOfBusiness || '');
        setLogo(buyerData.logo || '');
        setBusinessRegistrationNumber(buyerData.businessRegistrationNumber || '');
        setTaxIdentificationNumber(buyerData.taxIdentificationNumber || '');
        setWebsite(buyerData.website || '');
        setTypeOfProductsExported(buyerData.typeOfProductsExported || '');
        setExportDestinations(buyerData.exportDestinations || '');
        setCertifications(buyerData.certifications || '');
        setInsuranceInformation(buyerData.insuranceInformation || '');
        setYearsOfExperience(buyerData.yearsOfExperience || '');
        setBusinessDescription(buyerData.businessDescription || '');
        setSocialMediaLinks(buyerData.socialMediaLinks || '');
      }
    // const validateFields = () => {
    //     let retVal = true;
    //     if (!businessName) {
    //         fieldWithIdRequired('businessName');
    //         toast.warn("Business Name is required.");
    //         retVal = false;
    //       }
    //       if (!email) {
    //         fieldWithIdRequired('email');
    //         toast.warn("Email is required.");
    //         retVal = false;
    //       }
    //       if (!telephone) {
    //         fieldWithIdRequired('mobileNumber');
    //         toast.warn("Telephone is required.");
    //         retVal = false;
    //       }
    //       if (!businessAddress) {
    //         fieldWithIdRequired('businessAddress');
    //         toast.warn("Business Address is required.");
    //         retVal = false;
    //       }
    //       if (!ownersLastName) {
    //         fieldWithIdRequired('ownersLastName');
    //         toast.warn("Owner's Last Name is required.");
    //         retVal = false;
    //       }
    //       if (!ownersFirstName) {
    //         fieldWithIdRequired('ownersFirstName');
    //         toast.warn("Owner's First Name is required.");
    //         retVal = false;
    //       }
    //       if (!ownersHomeAddress) {
    //         fieldWithIdRequired('ownersHomeAddress');
    //         toast.warn("Owner's Home Address is required.");
    //         retVal = false;
    //       }
    //       if (!startingYearOfBusiness) {
    //         fieldWithIdRequired('startingYearOfBusiness');
    //         toast.warn("Starting Year of Business is required.");
    //         retVal = false;
    //       }
    //       return retVal;
    //     // const requiredFields = [
    //     //     { field: buyerData.buyerInfoId, fieldName: 'buyerInfoId' },
    //     //     { field: buyerData.code, fieldName: 'code' },
    //     //     { field: buyerData.userId, fieldName: 'userId' },
    //     //     { field: buyerData.businessName, fieldName: 'Business Name' },
    //     //     { field: buyerData.email, fieldName: 'Email' },
    //     //     { field: buyerData.mobileNumber, fieldName: 'mobileNumber' },
    //     //     { field: buyerData.businessAddress, fieldName: 'Business Address' },
    //     //     { field: buyerData.ownersLastName, fieldName: 'Owner\'s Last Name' },
    //     //     { field: buyerData.ownersFirstName, fieldName: 'Owner\'s First Name' },
    //     //     { field: buyerData.ownersHomeAddress, fieldName: 'Owner\'s Home Address' },
    //     //     { field: buyerData.startingYearOfBusiness, fieldName: 'Starting Year of Business' }
    //     //       // { field: buyerData.taxIdentificationNumber, fieldName: 'taxIdentificationNumber' }
    //     //     // { field: buyerData.website, fieldName: 'website' }
    //     //     // { field: buyerData.typeOfProductsExported, fieldName: 'typeOfProductsExported' }
    //     //     // { field: buyerData.exportDestinations, fieldName: 'exportDestinations' }
    //     //     // { field: buyerData.certifications, fieldName: 'certifications' }
    //     //     // { field: buyerData.insuranceInformation, fieldName: 'insuranceInformation' }
    //     //     // { field: buyerData.businessDescription, fieldName: 'businessDescription' }
    //     //     // { field: buyerData.socialMediaLinks, fieldName: 'socialMediaLinks' }
    //     //     // { field: buyerData.buyerInfoId, fieldName: 'buyerInfoId' },
    //     // ];

    //     // requiredFields.forEach(({ field, fieldName }) => {
    //     //     if (!field) {
    //     //         fieldWithIdRequired(fieldName);
    //     //         toast.warn(`${fieldName} is required.`);
    //     //         isValid = false;
    //     //     }
    //     // });

    //     // return isValid;
        
    // };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setbuyerData({
                    ...buyerData,
                    logo: reader.result // Base64 string
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your login access. Please try again later.");
            return;
        }

        // if (!validateFields()) {
        //     return;
        // }

        const data = {
            ...buyerData,
            logo: buyerData.logo,  // Optional: include logoFile name
        };

        const requestOptions = {
            method: buyerData.buyerInfoId ? "PUT" : "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
        };

        const apiUrl = buyerData.buyerInfoId
            ? `${baseURL}/buyers/${buyerData.buyerInfoId}`  // If buyerInfoId exists, update the company data
            : `${baseURL}/buyers`;  // If no buyerInfoId, create new company data

        setShowPreloader(true);

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (response.ok) {
                if (!buyerData.buyerInfoId) {
                    const result = await response.json();
                    setbuyerData(prevData => ({ ...prevData, buyerInfoId: result.id }));
                }
                toast.success("Update successful");
            } else {
                console.error('Failed to update company information');
            }
        } catch (error) {
            toast.error("Error updating the company information.");
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="page-wrapper">
                <SideBarWrapper />
                <div className="page-content">
                    <Navbar />
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Buyers Profile</li>
                        </ol>
                    </div>

                    <div className="main-container">
                        <div className="row gutters">
                            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="account-settings">
                                            <div className="company-profile text-center">
                                                <div className="company-logo mb-2">
                                                    {buyerData.logo && <img src={buyerData.logo} alt="Company Logo" />}
                                                </div>
                                                <h5 className="user-name">{buyerData.businessName}</h5>
                                                <h6 className="user-email">{buyerData.email}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-5 col-md-4 col-sm-4 col-12">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <div className="card-title">Update Buyer Info</div>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row gutters">
                                                {[ 
                                                    { label: 'Business Name', value: buyerData.businessName, onChange: (value) => setbuyerData({ ...buyerData, businessName: value }) },
                                                    { label: 'Email', value: buyerData.email, onChange: (value) => setbuyerData({ ...buyerData, email: value }) },
                                                    { label: 'Mobile Number', value: buyerData.mobileNumber, onChange: (value) => setbuyerData({ ...buyerData, mobileNumber: value }) },
                                                    { label: 'Business Address', value: buyerData.businessAddress, onChange: (value) => setbuyerData({ ...buyerData, businessAddress: value }) },
                                                    { label: 'Owner\'s Last Name', value: buyerData.ownersLastName, onChange: (value) => setbuyerData({ ...buyerData, ownersLastName: value }) },
                                                    { label: 'Owner\'s First Name', value: buyerData.ownersFirstName, onChange: (value) => setbuyerData({ ...buyerData, ownersFirstName: value }) },
                                                    { label: 'Owner\'s Home Address', value: buyerData.ownersHomeAddress, onChange: (value) => setbuyerData({ ...buyerData, ownersHomeAddress: value }) },
                                                    { label: 'Starting Year of Business', value: buyerData.startingYearOfBusiness, onChange: (value) => setbuyerData({ ...buyerData, startingYearOfBusiness: value }) },
                                                    { label: 'Business Registration Number', value: buyerData.businessRegistrationNumber, onChange: (value) => setbuyerData({ ...buyerData, businessRegistrationNumber: value }) },
                                                    { label: 'Tax Identification Number', value: buyerData.taxIdentificationNumber, onChange: (value) => setbuyerData({ ...buyerData, taxIdentificationNumber: value }) },
                                                    { label: 'Type Of Products Exported', value: buyerData.typeOfProductsExported, onChange: (value) => setbuyerData({ ...buyerData, typeOfProductsExported: value }) },
                                                ].map(({ label, value, onChange }, index) => (
                                                    <div key={index} className="col-md-6 col-12">
                                                        <div className="form-group">
                                                            <label htmlFor={label}>{label}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id={label}
                                                                value={value}
                                                                onChange={(e) => onChange(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="logo">Company Logo</label>
                                                        <FileInput
                                                            type="file"
                                                            id="logo"
                                                            onChange={handleLogoChange}
                                                            accept="image/*"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 text-right">
                                                    <button type="submit" className="btn btn-primary">Save Changes</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BuyerInfo;
