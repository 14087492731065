import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SideBarWrapper from '../../components/common/SideBarWrapper';
import useAuth from '../../hooks/useAuth';
import { baseURL } from '../../utils/misc';
import Navbar from '../../layout/Navbar';
import Preloader from "../../components/common/Preloader";
import { Link, useNavigate } from 'react-router-dom';

function FarmerInfo() {
    const { user } = useAuth();
    const [showPreloader, setShowPreloader] = useState(false);

    // State variables for company information
    const [farmerData, setfarmerData] = useState({
        gln: '',
        code: '',
        userId: '',
        email: '',
        mobileNumber: '',
        lastName: '',
        firstName: '',
        otherNames: '',
        gender: '',
        startingYearOfFarming: '',
        profilePicture: '',
        homeAddress: '',
        farmerInfoId: null,  // For existing company ID
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.user) {
            fetchfarmerInfo();
        }
    }, [user]);

    const token = user?.user?.token;
    const email = user?.user?.email;

    const fetchfarmerInfo = async () => {
        const apiUrl = `${baseURL}/farmers/email/${email}`;
        setShowPreloader(true);

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const farmerData = await response.json();
                if (farmerData) {
                    setfarmerData(prevData => ({
                        ...prevData,
                        farmerInfoId: farmerData.id,
                        ...farmerData,
                    }));
                }
            } else {
                console.error('Failed to fetch company information');
            }
        } catch (error) {
            console.error('Error occurred:', error);
        } finally {
            setShowPreloader(false);
        }
    };

    const validateFields = () => {
        let isValid = true;
        const requiredFields = [
            { field: farmerData.farmerInfoId, fieldName: 'farmerInfoId' },
            { field: farmerData.code, fieldName: 'Code' },
            { field: farmerData.gln, fieldName: 'Gln' },
            { field: farmerData.gender, fieldName: 'Gender' },
            { field: farmerData.email, fieldName: 'Email' },
            { field: farmerData.mobileNumber, fieldName: 'Mobile Number' },
            { field: farmerData.lastName, fieldName: 'Last Name' },
            { field: farmerData.firstName, fieldName: 'First Name' },
            { field: farmerData.homeAddress, fieldName: 'Home Address' },
            { field: farmerData.startingYearOfFarming, fieldName: 'Starting Year of Farming' },
        ];

        requiredFields.forEach(({ field, fieldName }) => {
            if (!field) {
                toast.warn(`${fieldName} is required.`);
                isValid = false;
            }
        });

        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your login access. Please try again later.");
            return;
        }

        if (!validateFields()) {
            return;
        }

        const data = {
            ...farmerData,
            logo: farmerData.logo,  // Optional: include logoFile name
        };

        const requestOptions = {
            method: farmerData.farmerInfoId ? "PUT" : "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
        };

        const apiUrl = farmerData.farmerInfoId
            ? `${baseURL}/farmers/${farmerData.farmerInfoId}`
            : `${baseURL}/farmers`;

        setShowPreloader(true);

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (response.ok) {
                if (!farmerData.farmerInfoId) {
                    const result = await response.json();
                    setfarmerData(prevData => ({ ...prevData, farmerInfoId: result.id }));
                }
                toast.success("Update successful");
            } else {
                console.error('Failed to update company information');
            }
        } catch (error) {
            toast.error("Error updating the company information.");
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="page-wrapper">
                <SideBarWrapper />
                <div className="page-content">
                    <Navbar />
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Outgrowers Profile</li>
                        </ol>
                    </div>

                    <div className="main-container">
                        <div className="row gutters">
                            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="account-settings">
                                            <div className="company-profile text-center">
                                                <div className="company-logo mb-2">
                                                    {farmerData.profilePicture && <img src={farmerData.profilePicture} alt="Company Logo" />}
                                                </div>
                                                <h5 className="user-name">{farmerData.firstName} {farmerData.lastName}</h5>
                                                <h6 className="user-email">{farmerData.email}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-5 col-md-4 col-sm-4 col-12">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <div className="card-title">Update Outgrower Info</div>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row gutters">
                                                {/* Form Fields */}
                                                {[ 
                                                    // { label: 'Logo', value: farmerData.profilePicture, onChange: (value) => setfarmerData({ ...farmerData, profilePicture: value }) },
                                                    { label: 'GLN', value: farmerData.gln, onChange: (value) => setfarmerData({ ...farmerData, gln: value }) },
                                                    { label: 'Code', value: farmerData.code, onChange: (value) => setfarmerData({ ...farmerData, code: value }) },
                                                    { label: 'First Name', value: farmerData.firstName, onChange: (value) => setfarmerData({ ...farmerData, firstName: value }) },
                                                    { label: 'Last Name', value: farmerData.lastName, onChange: (value) => setfarmerData({ ...farmerData, lastName: value }) },
                                                    { label: 'Email', value: farmerData.email, onChange: (value) => setfarmerData({ ...farmerData, email: value }) },
                                                    { label: 'Mobile Number', value: farmerData.mobileNumber, onChange: (value) => setfarmerData({ ...farmerData, mobileNumber: value }) },
                                                    { label: 'Gender', value: farmerData.gender, onChange: (value) => setfarmerData({ ...farmerData, gender: value }) },
                                                    { label: 'Home Address', value: farmerData.homeAddress, onChange: (value) => setfarmerData({ ...farmerData, homeAddress: value }) },
                                                    { label: 'Starting Year of Farming', value: farmerData.startingYearOfFarming, onChange: (value) => setfarmerData({ ...farmerData, startingYearOfFarming: value }) },
                                                ].map(({ label, value, onChange }, index) => (
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" key={index}>
                                                        <div className="form-group">
                                                            <label htmlFor={label}>{label}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={value}
                                                                onChange={(e) => onChange(e.target.value)}
                                                                placeholder={`Enter ${label}`}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FarmerInfo;
