import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getFarmerDashTopCards } from "../../api/Dashboard/getFarmerDashTopCards";
import Preloader from "../common/Preloader";

const FarmerDashTopCards = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [dashTopCardCounts, setDashTopCardCounts] = useState(null);
    const [showPreloader, setShowPreloader] = useState(false);

    const fetchDashboardData = async () => {
        try {
            setShowPreloader(true);
            const data = await getFarmerDashTopCards(user.user.token);
            setDashTopCardCounts(data);
        } catch (error) {
            console.error("Failed to fetch dashboard data:", error);
            toast.error("Failed to fetch data. Please try again.");
        } finally {
            setShowPreloader(false);
        }
    };

    useEffect(() => {
        if (!(user && user.user.token)) {
            navigate("/login");
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }

        // Initial fetch
        fetchDashboardData();

        // Set up interval for refreshing data every 5 minutes
        const intervalId = setInterval(fetchDashboardData, 300000); // 5 minutes = 300,000 ms

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [user, navigate]);

    return (
        <>
            <div className="row gutters">
                <Preloader show={showPreloader} />
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="info-stats4">
                        <div className="info-icon">
                            <i className="icon-gift"></i>
                        </div>
                        <div className="sale-num">
                            <h3>{dashTopCardCounts?.fieldsCount ?? "--"}</h3>
                            <p>Number of Fields</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="info-stats4">
                        <div className="info-icon">
                            <i className="icon-shopping_basket"></i>
                        </div>
                        <div className="sale-num">
                            <h3>
                                {dashTopCardCounts
                                    ? dashTopCardCounts.crops_NotOrganicCount +
                                      dashTopCardCounts.crops_OrganicCount
                                    : "--"}
                            </h3>
                            <p>
                                Crops: <em>Organic-{dashTopCardCounts?.crops_OrganicCount ?? "--"}, Conventional-{dashTopCardCounts?.crops_NotOrganicCount ?? "--"}</em>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="info-stats4">
                        <div className="info-icon">
                            <i className="icon-shopping_basket"></i>
                        </div>
                        <div className="sale-num">
                            <h3>{dashTopCardCounts?.harvestsCount ?? "--"}</h3>
                            <p>Number of Harvests</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="info-stats4">
                        <div className="info-icon">
                            <i className="icon-format_color_fill"></i>
                        </div>
                        <div className="sale-num">
                            <h3>{dashTopCardCounts?.plantingsCount ?? "--"}</h3>
                            <p>Planting Records</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FarmerDashTopCards;
