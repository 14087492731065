import React, { useEffect, useState } from 'react';
import Navbar from "../../layout/Navbar";
import SideBarWrapper from "../../components/common/SideBarWrapper";
import useAuth from "../../hooks/useAuth";
import { doesRoleExist, hasRoleInWorkflowRoles } from "../../utils/misc";
import "./Home.css"; // Import the custom CSS file
import Footer from '../../layout/Footer';
import AttentionRequiredContracts from '../../components/Contracts/AttentionRequiredContracts';
import { getAdminDashTopCards } from '../../api/Dashboard/getAdminDashTopCards';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Preloader from '../../components/common/Preloader';
import AdminDashActivities from '../../components/Home/AdminDashActivities';
import AdminDashExportContracts from '../../components/Home/AdminDashExportContracts';
import AdminDashQuickStats from '../../components/Home/AdminDashQuickStats';
import AdminDashTopCards from '../../components/Home/AdminDashTopCards';
import FarmerDashTopCards from '../../components/Home/FarmerDashTopCards';
import BuyerDashTopCards from '../../components/Home/BuyerDashTopCards';
import { runningCompany } from '../../appSetup';

function Home() {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [showPreloader, setShowPreloader] = useState(false);

	useEffect(() => {
		if (!(user && user.user.token)) {
			navigate("/login");
			toast.warn("You seem to have lost your access token. Please try again later.");
			return;
		}
	}, []);
	

	return (
		<>
			<div className="page-wrapper">
				<Preloader show={showPreloader} />
				<SideBarWrapper />
				<div className="page-content">
					<Navbar />
					<div className="page-header">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">Home</li>
							<li className="breadcrumb-item active">Dashboard</li>
						</ol>
					</div>
					<div className="main-container">
						{user && user.user && user.user.roles && hasRoleInWorkflowRoles(user.user.roles) && (<>
							<AttentionRequiredContracts />
						</>)}

						{/* TOP CARDS */}
						{doesRoleExist(user, "Admin") && <AdminDashTopCards /> }
						{(doesRoleExist(user, "Outgrower") || doesRoleExist(user, "Farmer")) && <FarmerDashTopCards /> }
						{doesRoleExist(user, "Buyer") && <BuyerDashTopCards /> }
						{/* END: TOP CARDS */}


						{doesRoleExist(user, "Admin") && ( 
							<div className="row gutters">
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
									<AdminDashQuickStats />
								</div>

								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
									<AdminDashActivities />
								</div>

								<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
									<AdminDashExportContracts />
								</div>
							</div>
						)}

						{!doesRoleExist(user, "Admin") && (
							<div className="row gutters">
								<div className="col-12">
									<div className="card">
										{/* <div className="card-header">
											<div className="card-title">[title]</div>
										</div> */}
										<div className="card-body">
											<div className="customScroll5">
												<div className="quick-analytics">
												<img src={`${window.location.origin}/assets/img/company_logos/${runningCompany.logoTiny}`} alt={`${runningCompany.name} Logo`} style={{ display: 'block', margin: 'auto', height: '190px', opacity: 0.15, }} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}

export default Home;
