import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { baseURL } from "../../../utils/misc";
import { attachActors, isoToDateTime } from "../../../utils/helpers";
import Preloader from "../../common/Preloader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Invitations = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTracker, setSelectedTracker] = useState(null);
  const [trackerDetails, setTrackerDetails] = useState(null);
  const [targetType, setTargetType] = useState('');

  const $ = require("jquery");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      $(document).ready(function () {
        const dataTable = $("#data-table").DataTable();

        if (dataTable) {
          dataTable.destroy();
        }

        $("#data-table").DataTable();
      });
    }
  }, [items]);

  const getData = async () => {
    setShowPreloader(true);
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }
    try {
      const response = await fetch(`${baseURL}/invitations`, {
        headers: {
          Authorization: `Bearer ${user.user.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setItems(await attachActors(user.user.token, data, "createdBy", "createdByUser"));
      } else {
        toast.warn("Error fetching Invitations!");
      }
    } catch (error) {
      toast.error("Error fetching Invitations!");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleTrackerClick = async (item) => {
    setSelectedTracker(item);
    setTargetType(item.target);
    setShowModal(true);

    try {
      const endpoint = item.target === "Farmer" ? `/farmers/email/${item.email}` : `/buyers/email/${item.email}`;
      const response = await fetch(`${baseURL}${endpoint}`, {
        headers: {
          Authorization: `Bearer ${user.user.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTrackerDetails(data);
      } else {
        toast.warn("Error fetching tracker details!");
      }
    } catch (error) {
      toast.error("Error fetching tracker details!");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedTracker(null);
    setTrackerDetails(null);
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">List of Invitations Sent</div>
              <Link to={`/registration/invitations/create`} className="btn btn-primary float-end">
                Send a New Invitation
              </Link>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="table-responsive">
                    <table id="data-table" className="table custom-table">
                      <thead className="thead-dark">
                        <tr>
                          <th>Telephone</th>
                          <th>Email</th>
                          <th>Target</th>
                          <th>Created Date</th>
                          <th>Expiry</th>
                          <th>Sent By</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items && items.length > 0 ? items.map((item, index) => (
                          <tr key={index}>
                            <td>{item.telephone}</td>
                            <td>{item.email}</td>
                            <td>{item.target}</td>
                            <td>{isoToDateTime(item.createdDate)}</td>
                            <td>{isoToDateTime(item.expiry)}</td>
                            <td>{item.createdByUser}</td>
                            <td>{item.status}</td>
                            <td>
                              {item.status !== "Pending" ? (
                               <span
                               className="btn btn-secondary btn-sm" // Add "btn-sm" class to make it smaller
                               style={{ cursor: "pointer", padding: "4px 6px", fontSize: "0.875rem" }} // Adjust padding and font size
                               onClick={() => handleTrackerClick(item)}
                             >
                               View Target
                             </span>
                              ) : (
                                <span className="text-muted"></span>
                              )}
                            </td>
                          </tr>
                        )) : (<tr><td colSpan="8" className="text-center"><em>No data available!</em></td></tr>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}

      {/* Tracker Modal */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{targetType ? targetType + ' ' : ''}Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  {trackerDetails ? (
    <>
      {selectedTracker.target === "Farmer" ? (
        // Farmers Information
        <>
         <p><strong>Profile Picture:</strong> {trackerDetails.profilePicture || 'n/a'}</p>
         <p><strong>Code:</strong> {trackerDetails.code || 'n/a'}</p>
          <p><strong>First Name:</strong> {trackerDetails.firstName || 'n/a'}</p>
          <p><strong>Last Name:</strong> {trackerDetails.lastName || 'n/a'}</p>
          <p><strong>Other Names:</strong> {trackerDetails.otherNames || 'n/a'}</p>
          <p><strong>Email:</strong> {trackerDetails.email || 'n/a'}</p>
          <p><strong>Gender:</strong> {trackerDetails.gender || 'n/a'}</p>
          <p><strong>Base Level:</strong> {trackerDetails.baseLevel || 'n/a'}</p>
          <p><strong>Mobile Number:</strong> {trackerDetails.mobileNumber || 'n/a'}</p>
          <p><strong>Home Address:</strong> {trackerDetails.homeAddress || 'n/a'}</p>
          <p><strong>Starting Year of Farming:</strong> {trackerDetails.startingYearOfFarming || 'n/a'}</p>
          <p><strong>GLN:</strong> {trackerDetails.gln || 'n/a'}</p>
          <p><strong>Status:</strong> {trackerDetails.status || 'n/a'}</p>
        </>
      ) : (
        // Buyers Information
        <>
          <p><strong>Code:</strong> {trackerDetails.code || 'n/a'}</p>
          <p><strong>Logo:</strong> {trackerDetails.logo || 'n/a'}</p>
          <p><strong>Business Name:</strong> {trackerDetails.businessName || 'n/a'}</p>
          <p><strong>Owner's First Name:</strong> {trackerDetails.ownersFirstName || 'n/a'}</p>
          <p><strong>Owner's Last Name:</strong> {trackerDetails.ownersLastName || 'n/a'}</p>
          <p><strong>Owner's Other Names:</strong> {trackerDetails.ownersOtherNames || 'n/a'}</p>
          <p><strong>Mobile Number:</strong> {trackerDetails.mobileNumber || 'n/a'}</p>
          <p><strong>Email:</strong> {trackerDetails.email || 'n/a'}</p>
          <p><strong>Business Address:</strong> {trackerDetails.businessAddress || 'n/a'}</p>
          <p><strong>Starting Year of Business:</strong> {trackerDetails.startingYearOfBusiness || 'n/a'}</p>
          <p><strong>Type of Products Exported:</strong> {trackerDetails.typeOfProductsExported || 'n/a'}</p>
          <p><strong>Certifications:</strong> {trackerDetails.certifications || 'n/a'}</p>
          <p><strong>Years of Experience:</strong> {trackerDetails.yearsOfExperience || 'n/a'}</p>
          <p><strong>Business Description:</strong> {trackerDetails.businessDescription || 'n/a'}</p>
          <p><strong>Social Media Links:</strong> {trackerDetails.socialMediaLinks || 'n/a'}</p>
          <p><strong>Website:</strong> {trackerDetails.website || 'n/a'}</p>
        </>
      )}
    </>
  ) : (
    <p>Loading</p>
  )}
</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Invitations;
