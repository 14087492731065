import React, {
  useEffect,
  useState,
} from 'react';

function SetAssignments({
  allItems,
  setAllItems,
  selectedItems,
  setSelectedItems,
  allItemsLabel,
  sellectedItemsLabel
}) {
  useEffect(() => {
    // Filter out items from allItems that are not present in selectedIds
    const remainingAllItems = removeSelectedItemsFromAll();

    const uniqueAllItems = getUniqueItemsByValue(remainingAllItems, 'value');
    const uniqueSelectedItems = getUniqueItemsByValue(selectedItems, 'value');

    setAllItems(uniqueAllItems);
    setSelectedItems(uniqueSelectedItems);
  }, []);

  function removeSelectedItemsFromAll() {
    // Create an array of selected item values
    const selectedValues = selectedItems.map(item => item.value);
  
    // Filter out items from allItems that are not present in selectedIds
    const remainingItems = allItems.filter(item => !selectedValues.includes(item.value));
  
    return remainingItems; 
  }
  
  function getUniqueItemsByValue(items, property) {
    // Create a Set to store unique property values
    const uniqueValues = new Set();
  
    // Filter the items array to keep only unique items based on the property value
    const uniqueItems = items.filter(item => {
      // If the property value is not in the Set, add it and return true to keep the item
      if (!uniqueValues.has(item[property])) {
        uniqueValues.add(item[property]);
        return true;
      }
      // If the property value is already in the Set, return false to exclude the item
      return false;
    });
  
    return uniqueItems;
  }

  const handleAddClick = () => {
    const selectedOptions = document.querySelectorAll(
      ".source-select option:checked"
    );
    const valuesToAdd = Array.from(selectedOptions, (option) => option.value);

    const itemsToAdd = allItems.filter((item) =>
      valuesToAdd.includes(item.value)
    );

    setSelectedItems((prevSelectedItems) => [
      ...prevSelectedItems,
      ...itemsToAdd,
    ]);

    setAllItems((prevAllItems2) =>
      prevAllItems2.filter((item) => !valuesToAdd.includes(item.value))
    );
  };

  const handleRemoveClick = () => {
    const selectedOptions = document.querySelectorAll(
      ".destination-select option:checked"
    );
    const valuesToRemove = Array.from(
      selectedOptions,
      (option) => option.value
    );

    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((item) => !valuesToRemove.includes(item.value))
    );

    const itemsToRemove = selectedItems.filter((item) =>
      valuesToRemove.includes(item.value)
    );

    setAllItems((prevAllItems2) => [...prevAllItems2, ...itemsToRemove]);
  };

  return (
    <>
      <div className="row gutters">
        <div className="col-lg-5">
          <div className="listbox p-2 border rounded my_profile_setting_input ui_kit_select_search form-group">
            <label htmlFor="availableItems">
              <em>{allItemsLabel}</em>
            </label>
            <select
              className="selectpicker source-select form-select form-control"
              multiple
              style={{ minHeight: "360px" }}
              size={Math.min(5, allItems.label)}
            >
              {allItems.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="my_profile_setting_input col-lg-2 d-flex flex-column align-items-center justify-content-center form-group">
          <button
            type="button"
            onClick={handleAddClick}
            className="btn btn-primary mb-2"
          >
            &gt; &gt;
          </button>
          <button
            type="button"
            onClick={handleRemoveClick}
            className="btn btn-primary"
          >
            &lt; &lt;
          </button>
        </div>
        <div className="col-lg-5">
          <div className="listbox p-2 border rounded my_profile_setting_input ui_kit_select_search form-group">
            <label htmlFor="availableItems">
              <em>{sellectedItemsLabel}</em>
            </label>
            <select
              className="selectpicker destination-select form-select form-control"
              multiple
              style={{ minHeight: "360px" }}
              size={Math.min(5, selectedItems.label)}
            >
              {selectedItems.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetAssignments;
