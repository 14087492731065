import { Link, useNavigate } from "react-router-dom";
import { getFullBuyerName, getFullCropName, isoToDate } from "../../utils/helpers";
import { useEffect, useState } from "react";
import { getDocumentByContractId } from "../../api/Documents/getGetAllDocuments";
import useAuth from "../../hooks/useAuth";
import { baseURL } from "../../utils/misc";
import { runningCompanyName } from "../../appSetup";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const ContractView = ({ contract }) => {
  const [contractDocument, setContractDocument] = useState([]);
  const { user } = useAuth();
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();

  const [selectedTracker, setSelectedTracker] = useState(null);
  const [showBuyerModal, setShowBuyerModal] = useState(false);
  const [trackerDetails, setTrackerDetails] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [sellerDetails, setSellerDetails] = useState(null);

  const viewBuyerInformation = (buyer) => {
    setTrackerDetails(buyer); // Assuming `buyer` contains the necessary details
    setShowBuyerModal(true);
  };

  // Function to fetch and show seller details when clicking on the eye icon
  const handleTrackerClick = async () => {
    console.log("Opening seller modal..."); // Log when the modal is about to open
    setShowModal(true); // Show the modal first

    try {
      const endpoint = `${baseURL}/companyinformation`; // Adjust the endpoint if needed
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${user.user.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched seller details:", data); // Log the fetched data
        setTrackerDetails(data); // Set the fetched data
      } else {
        toast.warn("Error fetching seller details!");
      }
    } catch (error) {
      toast.error("Error fetching seller details!");
    }
  };

  const closeModal = () => {
    setShowModal(false); // Hide the seller modal
    setTrackerDetails(null); // Clear tracker details
  };

  const closeBuyerModal = () => {
    setShowBuyerModal(false); // Hide the buyer modal
    setTrackerDetails(null); // Clear buyer details
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
    }

    setShowPreloader(true);
    const documents = await getDocumentByContractId(user.user.token, contract.id);
    setContractDocument(documents);
    setShowPreloader(false);
  };

  return (
    <>
      {/* Contract View */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card" style={{ backgroundColor: "#eff1f5" }}>
            <div className="card-header">
              <div className="card-title">
                {`${contract ? contract.contractCode + " - " : "Contract "}Details`}
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                {/* Crop */}
                <div className="col-12 mb-3">
                  <p>
                    <strong>Crop:</strong> {contract && contract.crop ? getFullCropName(contract.crop) : "n/a"}
                  </p>
                </div>

                {/* Seller */}
                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center" style={{ paddingLeft: "1px" }}>
                    <p className="mb-0">
                      <strong>Seller:</strong> {runningCompanyName}
                    </p>
                    <i
                      className="icon-eye ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={handleTrackerClick} // Click to show seller details
                    />
                  </div>
                </div>

                {/* Buyer */}
                <div className="row gutters">
                  <div className="col-12 mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <p>
                        <strong>Buyer:</strong> {contract && contract.buyer ? contract.buyer.businessName : "n/a"}
                      </p>
                      {contract && contract.buyer && (
                        <i
                          className="icon-eye"
                          style={{ cursor: "pointer" }}
                          onClick={() => viewBuyerInformation(contract.buyer)}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* Contract Details */}
                <div className="col-12 mb-3">
                  <p><strong>Description:</strong> {contract ? contract.description : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Details:</strong> {contract ? contract.details : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Start Date:</strong> {contract ? isoToDate(contract.startDate) : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>End Date:</strong> {contract ? isoToDate(contract.endDate) : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Contract Amount:</strong> {contract && contract.contractAmount ? contract.contractAmount : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Currency:</strong> {contract && contract.currency ? contract.currency : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Payment Terms:</strong> {contract && contract.paymentTerms ? contract.paymentTerms : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Delivery Terms:</strong> {contract && contract.deliveryTerms ? contract.deliveryTerms : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Exporter Contact Information:</strong> {contract && contract.exporterContactInfo ? contract.exporterContactInfo : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Buyer Contact Information:</strong> {contract && contract.buyerContactInfo ? contract.buyerContactInfo : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Attachment:</strong>
                    {contract && contract.attachments ? (
                      <Link to={contract.attachments} target="_blank" rel="noopener noreferrer">
                        <span className="icon-eye1">
                          <em>View</em>
                        </span>
                      </Link>
                    ) : (
                      'n/a'
                    )}
                  </p>
                </div>

                {/* Documents */}
                <div className="col-12 mb-3">
                  <p><strong>Contract Document(s):</strong></p>
                  {contractDocument && contractDocument.length > 0 ? (
                    contractDocument.map((doc, index) => (
                      <Link key={index} to={doc.document.path} target="_blank" rel="noopener noreferrer">
                        <button className="btn btn-primary icon-eye1"> &nbsp;
                          View {doc.document.name}
                        </button>
                        {index < contractDocument.length - 1 } {/* Separator */}
                      </Link>
                    ))
                  ) : (
                    'n/a'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Buyer Modal */}
      <Modal show={showBuyerModal} onHide={closeBuyerModal}>
        <Modal.Header closeButton>
          <Modal.Title>Buyer Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {trackerDetails ? (
            <>
              <p><strong>Code:</strong> {trackerDetails.code || "n/a"}</p>
              <p><strong>Logo:</strong></p>
              {trackerDetails.logo ? (
                <img
                  src={trackerDetails.logo}
                  alt="Logo"
                  style={{ width: "100px", height: "100px", objectFit: "contain" }}
                />
              ) : (
                "n/a"
              )}
              <p><strong>Business Name:</strong> {trackerDetails.businessName || "n/a"}</p>
              <p><strong>Owner's First Name:</strong> {trackerDetails.ownersFirstName || "n/a"}</p>
              <p><strong>Owner's Last Name:</strong> {trackerDetails.ownersLastName || "n/a"}</p>
              <p><strong>Owner's Other Names:</strong> {trackerDetails.ownersOtherNames || "n/a"}</p>
            </>
          ) : (
            "Loading buyer information..."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeBuyerModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Seller Modal */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Seller Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {trackerDetails ? (
            <>
              <p><strong>GLN:</strong> {trackerDetails.gln || "n/a"}</p>
              <p><strong>PPRSD Code:</strong> {trackerDetails.pprsdCode || "n/a"}</p>
              <p><strong>Business Name:</strong> {trackerDetails.businessName || "n/a"}</p>
              <p><strong>Email:</strong> {trackerDetails.email || "n/a"}</p>
              <p><strong>Telephone:</strong> {trackerDetails.telephone || "n/a"}</p>
              <p><strong>Business Address:</strong> {trackerDetails.businessAddress || "n/a"}</p>
              <p><strong>Owner's First Name:</strong> {trackerDetails.ownersFirstName || "n/a"}</p>
              <p><strong>Owner's Last Name:</strong> {trackerDetails.ownersLastName || "n/a"}</p>
              <p><strong>Owner's Other Names:</strong> {trackerDetails.ownersOtherNames || "n/a"}</p>
              <p><strong>Owner's Home Address:</strong> {trackerDetails.ownersHomeAddress || "n/a"}</p>
              <p><strong>Starting Year of Business:</strong> {trackerDetails.startingYearOfBusiness || "n/a"}</p>
            </>
          ) : (
            "Loading seller information..."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContractView;
