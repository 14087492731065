import { useEffect, useState } from "react";
import { getAllContracts } from "../../api/Contracts/getAllContracts";
import useAuth from "../../hooks/useAuth";
import { abbreviateString, formatAndInterpretDate, getFullBuyerName_NameFirst } from "../../utils/helpers";

const AdminDashExportContracts = () => {
    const { user } = useAuth();
    const [contracts, setContracts] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const fetchContracts = async () => {
        setLoadingData(true);
        try {
            const data = await getAllContracts(user.user.token);
            setContracts(data.slice(0, 7)); // Fetch only the first 7 contracts
        } catch (error) {
            console.error("Error fetching contracts:", error);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        // Initial data fetch
        fetchContracts();

        // Refresh data every 5 minutes
        const intervalId = setInterval(fetchContracts, 300000); // 300,000 ms = 5 minutes

        // Cleanup on component unmount
        return () => clearInterval(intervalId);
    }, [user]);

    return (
        <div className="card" style={{ minHeight: "357px" }}>
            <div className="card-header">
                <div className="card-title">Export Contracts for the Week</div>
            </div>
            <div className="card-body">
                {loadingData ? (
                    <div>
                        <em>Loading...</em>
                    </div>
                ) : contracts && contracts.length > 0 ? (
                    <div className="customScroll5">
                        <ul className="user-messages">
                            {contracts.map((contract, index) => (
                                <li key={index} className="clearfix">
                                    <div className="customer">
                                        {abbreviateString(contract.buyer?.businessName || "", 2)}
                                    </div>
                                    <div className="delivery-details">
                                        <span className="badge badge-primary">{contract.contractStatus}</span>
                                        <h5>{getFullBuyerName_NameFirst(contract.buyer)}</h5>
                                        <p>{contract.description}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <em>No contracts detected in the last 7 days.</em>
                )}
            </div>
        </div>
    );
};

export default AdminDashExportContracts;
