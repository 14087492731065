
/* DEFAULT COMPANY */
// export const env_COMPANY_ID = 'SYNERGY_TRACE_SYS';
/* localhost */
// export const env_BASE_URL = 'https://localhost:7098/api/v1';


/* DEV TEAM */
// export const env_BASE_URL = 'http://74.249.24.86:8070/api/v1';

/* UAT */
//export const env_BASE_URL = 'https://traceabilityapi.aoholdings.net/api/v1';
//export const env_BASE_URL = 'http://74.249.24.86:8073/api/v1';

/* LIVE */
//export const env_BASE_URL = 'http://74.249.24.86:8075/api/v1';


/* INTERNAL TESTS */
//export const env_BASE_URL = 'http://74.249.24.86:8071/api/v1';



//*************************************************


/* SUGARLAND LTD */
// export const env_COMPANY_ID = 'SUGARLAND_LTD';
// export const env_BASE_URL = 'https://traceabilityapi.sugarlandltd.com/api/v1';


/* BB HARMONICS */
// export const env_COMPANY_ID = 'BB_HARMONICS';
// export const env_BASE_URL = 'https://traceabilityapi.bbharmonics.com/api/v1';


/* 360 NATURALS */
// export const env_COMPANY_ID = '360_NATURALS';
// export const env_BASE_URL = 'https://traceabilityapi.360naturalsghana.com/api/v1';


/* SV NOBLE ENTERPRISE */
export const env_COMPANY_ID = 'SV_NOBEL_ENT';
export const env_BASE_URL = 'https://traceabilityapi.svnobleenterprise.com/api/v1';


/* TROPIGHA FARMS LTD */
// export const env_COMPANY_ID = 'TROPIGHA_FARMS_LTD';
// export const env_BASE_URL = 'https://traceabilityapi.tropighafarmsltd.com/api/v1';
