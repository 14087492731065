import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { attachActors, fieldRequired, fieldWithIdRequired, isoToDateTime } from "../../utils/helpers";
import { Modal, ModalFooter } from "react-bootstrap";

const FieldOptionsSetups = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  const [items, setItems] = useState([]);
  const [newItemToAdd, setNewItemToAdd] = useState('');

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();

  const $ = require("jquery");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      //Initialize DataTable when users are available
      $(document).ready(function () {
        const dataTable = $("#data-table").DataTable();

        // Destroy DataTable if it exists
        if (dataTable) {
          dataTable.destroy();
        }

        $("#data-table").DataTable({
          // Add any required DataTable options here
          columnDefs: [
            { targets: 0, width: "3%" }, // Adjust the width of the first column
          ],
        });
      });
    }
  }, []);

  const getData = async () => {
    setShowPreloader(true);
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }
    try {
      const response = await fetch(`${baseURL}/fieldoptionssetups`, {
        headers: {
          Authorization: `Bearer ${user.user.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setItems(await response.json());
      } else {
        toast.warn("Error fetching Field Options Setups!");
      }
    } catch (error) {
      toast.error("Error fetching Field Options Setups!");
    } finally {
      setShowPreloader(false);
    }
  };

  const addNewItem = (e) => {
    e.preventDefault();
  
    if (!newItemToAdd.trim()) {
      toast.warn("Please enter a valid item to add.");
      return;
    }
  
    // Prevent duplicate items in selectedRecord.values
    if (selectedRecord.values.includes(newItemToAdd.trim())) {
      toast.warn("The item already exists in the list.");
      return;
    }
  
    // Update the selectedRecord values only
    setSelectedRecord((prev) => ({
      ...prev,
      values: [...prev.values, newItemToAdd.trim()],
    }));
  
    setNewItemToAdd("");
    toast.success("Item added to the list.");
  };
  
  const removeSelectedItem = (e) => {
    e.preventDefault();
  
    const selectElement = document.querySelector("#availableItems");
    const selectedOptions = Array.from(selectElement.selectedOptions).map(
      (option) => option.value
    );
  
    if (selectedOptions.length === 0) {
      toast.warn("Please select an item to remove.");
      return;
    }
  
    // Update the selectedRecord values by filtering out the selected options
    setSelectedRecord((prev) => ({
      ...prev,
      values: prev.values.filter((value) => !selectedOptions.includes(value)),
    }));
  
    toast.success("Selected item(s) removed.");
  };
    
  const submitUpdatedItems = async (e) => {
    e.preventDefault();
  
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your login token. Please try again later.");
      return; // Ensure the function stops execution if the user is not authenticated
    }
  
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(selectedRecord), // selectedRecord now includes updated values
      redirect: "follow",
    };
  
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/fieldoptionssetups/${selectedRecord.id}`, requestOptions);
  
      if (response.ok) {
        setItems(await response.json());
        toast.success("The Field Option Setup has been successfully updated.");
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the Field Option Setup!");
    } finally {
      setShowPreloader(false);
    }
  };  

  const handleShowEditModal = (record) => {
    setSelectedRecord(record);
    setNewItemToAdd('');
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setNewItemToAdd('');
    setSelectedRecord(null);
  };
  
  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
              <div className="card-header">
                <div className="card-title">Field Options Setups</div>
                {/* <Link to={`/registration/invitations/create`} className="btn btn-primary float-end">
                  Create New Setup
                </Link> */}
              </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="table-responsive">
                    <table id="data-table" className="table custom-table">
                          <thead className="thead-dark">
                              <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  {/* <th>Code</th> */}
                                  <th>Values</th>
                                  <th>Actions</th>
                              </tr>
                          </thead>
                          <tbody>
                              {items && items.length > 0 ? items.map((item, index) => (
                                <>
                                  <tr key={index}> 
                                    <td>{index + 1})</td>
                                    <td>{item.name}</td>
                                    {/* <td>{item.code}</td> */}
                                    <td>{item?.values && item.values.map((value, index) => (<>{index > 0 && <br />}{value}</>))}</td>
                                    <td>
                                      <button type="button" className="btn btn-sm btn-secondary float-centre" onClick={() => handleShowEditModal(item) }>&nbsp;&nbsp;Edit&nbsp;&nbsp;</button>
                                    </td>
                                  </tr>
                                </>
                              ))  : (<tr key='no-data-available'><td colSpan="3" className="text-center"><em>No data available!</em></td></tr>)}
                          </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={submitRecords}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Row end */}

      <Modal size="md" show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
              <Modal.Title>{selectedRecord ? "Update " + selectedRecord.name : "--"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row gutters">
              <div className="col-lg-10">
                <div className="form-group">
                  <label htmlFor="availableItems">Available {selectedRecord?.name}</label>
                  <select
                    id="availableItems"
                    className="selectpicker source-select form-select form-control"
                    multiple
                    style={{ minHeight: "360px" }}
                    // size={Math.min(5, allItems.label)}
                  >
                    {selectedRecord?.values.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-group">
                  <br />
                  <button type="button" className="btn btn-sm btn-secondary" style={{width: "72px", marginTop: "5px"}} onClick={removeSelectedItem}>Remove</button>
                </div>
              </div>
            </div>
            <br />
            <div className="row gutters">
              <div className="col-lg-10">
                <div className="form-group">
                  <label htmlFor="newItemToAdd">Add New Item</label>
                  <input type="text" className="form-control" id="newItemToAdd" placeholder="New item..."
                    value={newItemToAdd}
                    onChange={(e) => {setNewItemToAdd(e.target.value); fieldRequired(e); }}
                  />
                  <div className="invalid-feedback">This is required</div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-group">
                  <br />
                  <button type="button" className="btn btn-sm btn-secondary" style={{width: "72px", marginTop: "5px"}} onClick={addNewItem}>Add</button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <ModalFooter>
            <button type="button" className="btn btn-primary float-end" style={{width: "110px"}} onClick={submitUpdatedItems}>Update</button>
          </ModalFooter>
      </Modal>
    </>
  );
};

export default FieldOptionsSetups;
