import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import Preloader from "../common/Preloader";
import TransferFormBasic from "./TransferFormBasic";
import CropInfo from "../Crop/CropInfo";

const TransferForm = ({ transferRecord, setTransferRecord, crop }) => {
  const { user } = useAuth();

  const [transferId, setTransferId] = useState(null);

  //env conditions data
  const [environmentalCondition, setEnvironmentalCondition] = useState();
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleEyeIconClick = () => {
    if (crop) {
      setModalData({ crop });
      setShowModal(true);
    } else {
      toast.warn("No crop data available to display.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };
  useEffect(() => {
    if (transferRecord) {
      setTransferId(transferRecord.id);

      if (transferRecord.environmentalCondition) {
        setEnvironmentalConditionId(transferRecord.environmentalCondition.id);
        setEnvironmentalCondition(transferRecord.environmentalCondition);
      }
    }
  }, [transferRecord]);
  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const data = {
      cropId:crop.id,
      date: transferRecord.date,
      destination: transferRecord.destination,
      giai: transferRecord.giai,
      origin: transferRecord.origin,
      transporterContact: transferRecord.transporterContact,
      remarks: transferRecord.remarks,
      vehicleDetails: transferRecord.vehicleDetails,
      transportMethod: transferRecord.transportMethod,
      transporterName: transferRecord.transporterName,
      quantity: transferRecord.quantity,
      quantityUnit: transferRecord.quantityUnit,
      environmentalCondition
    };


    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/transferrecords`, requestOptions);

      if (response.ok) {
        toast.success("The Transfer Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/transferrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the transferrecords.");
    } finally {
      setShowPreloader(false);
    }
  };
  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const data = {
      id: transferId,
      cropId:crop.id,
      date: transferRecord.date,
      destination: transferRecord.destination,
      giai: transferRecord.giai,
      origin: transferRecord.origin,
      transporterContact: transferRecord.transporterContact,
      remarks: transferRecord.remarks,
      vehicleDetails: transferRecord.vehicleDetails,
      transportMethod: transferRecord.transportMethod,
      transporterName: transferRecord.transporterName,
      quantity: transferRecord.quantity,
      quantityUnit:transferRecord.quantityUnit,
      environmentalConditionId,
      environmentalCondition
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/transferrecords/${transferId}`, requestOptions);

      if (response.ok) {
        toast.success("The Transfer Record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/transferrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the transfer record.");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Transfer Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/transferrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
              
                  <span> {crop && (
                                            <i className="icon-eye"
                                            onClick={handleEyeIconClick}
                                            style={{ cursor: "pointer" }} />)}</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <TransferFormBasic crop={crop} transferRecord={transferRecord} setTransferRecord={setTransferRecord} handleSubmit={transferId ? handleSubmitEdit : handleSubmitCreate} />
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
      {showModal && modalData && (
                <div
                    className="modal fade show"
                    style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="cropInfoModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="cropInfoModalLabel">Crop Information</h5>
                                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <CropInfo contract={modalData} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </>
  );
}

export default TransferForm;