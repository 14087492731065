import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getNutrientIngredientQuantityUnit, getNutrientMaturityStagesOfCrop, getNutrientSources } from "../../api/NutrientManagementRecords/getNutrientRecordsList";
import EnvironmentalCondition from "../common/EnvironmentalCondition";
import { getEquipmentUsedList } from "../../api/PackHouseRecords/getPackHouseRecordsList";
import Select from "react-select";
import CropInfo from "../Crop/CropInfo";

const NutrientForm = ({ nutrientRecords,crop }) => {
  const { user } = useAuth();

  const [date, setDate] = useState('');
  const [activeIngredients, setActiveIngredients] = useState('');
  const [appliedBy, setAppliedBy] = useState('');
  const [source, setSource] = useState('');
  const [supervisedBy, setSupervisedBy] = useState('');
  const [nameOfProduct, setNameOfProduct] = useState('');
  const [quantityApplied, setQuantityApplied] = useState('');
  const [quantityAppliedUnit, setQuantityAppliedUnit] = useState('');
  const [equipmentsUsed, setEquipmentsUsed] = useState([]);

  const [nutrientRecordId, setNutrientRecordId] = useState(null);
  const [code, setCode] = useState('');

  const [maturityStageOfCrop, setMaturityStageOfCrop] = useState('');
  const [associatedCost, setAssociatedCost] = useState('');
  const [maturityStageOfCropList, setMaturityStageOfCropList] = useState([]);
  const [ingredientQuantityUnitList, setIngredientQuantityUnitList] = useState([]);
  const [sourceList, setsourceList] = useState([]);
  const [equipmentUsedList, setEquipmentUsedList] = useState([]);


    //env conditions data
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');
  const [environmentalCondition, setEnvironmentalCondition] = useState();

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleEyeIconClick = () => {
      if (crop) {
        setModalData({ crop });
        setShowModal(true);
      } else {
        toast.warn("No crop data available to display.");
      }
    };

    const closeModal = () => {
      setShowModal(false);
      setModalData(null);
    };

  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {
    if (nutrientRecordId) {
      getNutrientsRecords(nutrientRecordId);
    }
  }, [nutrientRecordId])

  useEffect(() => {
    if (nutrientRecords) {
      setNutrientRecordId(nutrientRecords.id);
      setDate(nutrientRecords.date);
      setSource(nutrientRecords.source);
      setActiveIngredients(nutrientRecords.activeIngredients);
      setNameOfProduct(nutrientRecords.nameOfProduct);
      setMaturityStageOfCrop(nutrientRecords.maturityStageOfCrop);
      setQuantityApplied(nutrientRecords.quantityApplied);
      setQuantityAppliedUnit(nutrientRecords.quantityAppliedUnit);
      setAppliedBy(nutrientRecords.appliedBy);
      setSupervisedBy(nutrientRecords.supervisedBy);
      setAssociatedCost(nutrientRecords.associatedCost);
      setCode(nutrientRecords.code);
      setEquipmentsUsed(
        nutrientRecords.equipmentsUsed
          ? nutrientRecords.equipmentsUsed.map(equip => ({
              value: equip,
              label: equip
            }))
          : []
      );

      if (nutrientRecords.environmentalCondition) {
        setEnvironmentalConditionId(nutrientRecords.environmentalCondition.id);
        setEnvironmentalCondition(nutrientRecords.environmentalCondition);
      }

    }
  }, [nutrientRecords]);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    try {
      setShowPreloader(true);
      setMaturityStageOfCropList(await getNutrientMaturityStagesOfCrop(user.user.token));
      setIngredientQuantityUnitList(await getNutrientIngredientQuantityUnit(user.user.token));
      setsourceList(await getNutrientSources(user.user.token));
      setEquipmentUsedList(await getEquipmentUsedList(user.user.token));

    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getNutrientsRecords = async () => {

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/nutrientmanagementrecords/${nutrientRecordId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setDate(data.date);
        setActiveIngredients(data.activeIngredients);
        setMaturityStageOfCrop(data.maturityStageOfCrop);
        setAppliedBy(data.appliedBy);
        setSource(data.source);
        setSupervisedBy(data.supervisedBy);
        setNameOfProduct(data.nameOfProduct);
        setQuantityApplied(data.quantityApplied);
        setQuantityAppliedUnit(data.quantityAppliedUnit);
        setAssociatedCost(data.associatedCost);
        setCode(data.code);
        setEquipmentsUsed(
          data.equipmentsUsed
            ? data.equipmentsUsed.map(equip => ({
              value: equip,
              label: equip
            }))
            : []
        );

        if (data.environmentalCondition) {
          setEnvironmentalConditionId(data.environmentalCondition.id);
          setEnvironmentalCondition(data.environmentalCondition);
        }

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching nutrient records data.');
        console.error("Error fetching nutrient records data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }
    if (!activeIngredients) {
      fieldWithIdRequired('activeIngredients');
      toast.error("Active Ingredients is required.");
      retVal = false;
    }
    if (!nameOfProduct) {
      fieldWithIdRequired('nameOfProduct');
      toast.error("Name Of Product is required.");
      retVal = false;
    }

    if (!source) {
      fieldWithIdRequired('source');
      toast.error("Source is required.");
      retVal = false;
    }

    if (!maturityStageOfCrop) {
      fieldWithIdRequired('maturityStageOfCrop');
      toast.error("Maturity Stage Of Crop is required.");
      retVal = false;
    }

    if (!quantityApplied) {
      fieldWithIdRequired('quantityApplied');
      toast.error("Quantity Applied is required.");
      retVal = false;
    }

    if (!quantityAppliedUnit) {
      fieldWithIdRequired('quantityAppliedUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    if (!appliedBy) {
      fieldWithIdRequired('appliedBy');
      toast.error("Applied By is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }

    

    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      cropId:crop.id,
      date: date,
      activeIngredients: activeIngredients,
      maturityStageOfCrop: maturityStageOfCrop,
      supervisedBy: supervisedBy,
      appliedBy: appliedBy,
      nameOfProduct: nameOfProduct,
      source:source,
      quantityApplied: quantityApplied,
      associatedCost: associatedCost,
      equipmentsUsed: equipmentsUsed.map(option => option.value),
      quantityAppliedUnit:quantityAppliedUnit,
      environmentalCondition,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/nutrientmanagementrecords`, requestOptions);

      if (response.ok) {
        toast.success("The Planting Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/nutrientmanagementrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the nutrientmanagementrecords.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: nutrientRecordId,
      cropId:crop.id,
      date: date,
      activeIngredients: activeIngredients,
      maturityStageOfCrop: maturityStageOfCrop,
      supervisedBy: supervisedBy,
      appliedBy: appliedBy,
      nameOfProduct: nameOfProduct,
      source: source,
      quantityApplied: quantityApplied,
      quantityAppliedUnit: quantityAppliedUnit,
      associatedCost: associatedCost,
      equipmentsUsed: equipmentsUsed.map(option => option.value),
      environmentalConditionId,
      environmentalCondition,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/nutrientmanagementrecords/${nutrientRecordId}`, requestOptions);

      if (response.ok) {
        toast.success("The nutrient record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/nutrientmanagementrecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the nutrient record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const equipmentOptions = equipmentUsedList.map(equip => ({
    value: equip,
    label: equip
  }));
 
  const handleChange = (selectedOptions) => {
    setEquipmentsUsed(selectedOptions || []);
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Nutrient Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                
                  <Link to={`/nutrientmanagementrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                  <span> {crop && (<i className="icon-eye"
                                            onClick={handleEyeIconClick}
                                            style={{ cursor: "pointer" }} />)}</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

                {nutrientRecordId && 
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={code ? code : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      value={isoToDate(date)}
                      onChange={(e) => {setDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Maturity Stage Of Crop *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={maturityStageOfCrop}
                      onChange={(e) => { setMaturityStageOfCrop(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Maturity Stage Type...</option>
                      {maturityStageOfCropList && maturityStageOfCropList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="nameOfProduct">Name Of Product *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="nameOfProduct"
                      placeholder="Enter the name of Product..."
                      value={nameOfProduct}
                      onChange={(e) => {setNameOfProduct(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Active Ingredients *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="expectedYield"
                      placeholder="Enter the active ingredients..."
                      value={activeIngredients}
                      onChange={(e) => {setActiveIngredients(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Equipment Used</label>
                    <Select
                      id="equipmentSelection"
                      options={equipmentOptions}
                      onChange={handleChange}
                      placeholder="Select Equipment..."
                      isClearable
                      isMulti
                      value={equipmentsUsed}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityApplied">Quantity Applied *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="expectedYield"
                      placeholder="Enter the quantity applied..."
                      value={quantityApplied}
                      onChange={(e) => {setQuantityApplied(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUnit">Quantity Applied Unit *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={quantityAppliedUnit}
                      onChange={(e) => { setQuantityAppliedUnit(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Ingredient Applied Type...</option>
                      {ingredientQuantityUnitList && ingredientQuantityUnitList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="source">Source *</label>
                    <select
                      className="form-control form-control-lg"
                      id="source"
                      value={source}
                      onChange={(e) => { setSource(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Source Type...</option>
                      {sourceList && sourceList.map((source) => (
                        <option key={source} value={source}>
                          {source}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="appliedBy">Applied By *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="appliedBy"
                      placeholder="Enter the applied by..."
                      value={appliedBy}
                      onChange={(e) => {setAppliedBy(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Supervised By</label>
                    <input
                      type="text"
                      className="form-control"
                      id="supervisedBy"
                      placeholder="Enter the supervised by..."
                      value={supervisedBy}
                      onChange={(e) => setSupervisedBy(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="associatedCost">Associated Cost <em>(in GHS)</em></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter the Associated Cost"
                      id="associatedCost"
                      value={associatedCost}
                      onChange={(e) => setAssociatedCost(e.target.value)}
                    />
                  </div>
                </div>

              </div>
              
              <EnvironmentalCondition environmentalCondition={environmentalCondition} setEnvironmentalCondition={setEnvironmentalCondition} />

            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(nutrientRecordId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
      {showModal && modalData && (
                <div
                    className="modal fade show"
                    style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="cropInfoModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="cropInfoModalLabel">Crop Information</h5>
                                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <CropInfo contract={modalData} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </>
  );
}

export default NutrientForm;