import { env_COMPANY_ID } from "./env";

export const runningCompanyId = env_COMPANY_ID;

export const CompanyIds = {
    SynergyTraceSys: 'SYNERGY_TRACE_SYS',
    SugarlandLtd: 'SUGARLAND_LTD',
    BBHarmonics: 'BB_HARMONICS',
    E60Naturals: '360_NATURALS',
    TropighaFarmsLtd: 'TROPIGHA_FARMS_LTD',
    SVNoble: 'SV_NOBEL_ENT',
};

export const companySetup = [
    {
        id: CompanyIds.SynergyTraceSys,
        name: 'Synergy Traceability System',
        logo: 'Synergy Traceability System_Logo_Portrait.jpeg',
        logoTiny: 'Synergy Traceability System_Logo_Landscape.jpeg',
        helpLine: '+233 531 100 067',
        helpEmail: '',
        helpDoc: 'User Manual_S. V. Noble Traceability System.pdf',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
            'OUT-GROWERS',
        ]
    },
    {
        id: CompanyIds.SugarlandLtd,
        name: 'Sugarland Ltd',
        logo: 'SugarlandLtd.png',
        logoTiny: 'SugarlandLtd.png',
        helpLine: '+233 543 883 682, +233 244 607 086',
        helpEmail: 'sugarlandlimited@yahoo.com',
        helpDoc:'User Manual_Sugar Land Traceability System.pdf',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
        ]
    },
    {
        id: CompanyIds.BBHarmonics,
        name: 'BB Harmonics',
        logo: 'BBHarmonics.png',
        logoTiny: 'BBHarmonics.png',
        helpLine: '',
        helpEmail: '',
        helpDoc:'User Manual_B&B Hramonics Traceability System.pdf',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
        ]
    },
    {
        id: CompanyIds.E60Naturals,
        name: '360 Naturals',
        logo: '360Naturals.png',
        logoTiny: '360Naturals.png',
        helpLine: '+233 243 243 087',
        helpEmail: '360naturals@gmail.com',
        helpDoc:'User Manual_360 Naturals Traceability System.pdf',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
            'OUT-GROWERS',
        ]
    },
    {
        id: CompanyIds.TropighaFarmsLtd,
        name: 'Tropigha Farms Ltd',
        logo: 'TropighaFarmsLtd.jpg',
        logoTiny: 'TropighaFarmsLtd.jpg',
        helpLine: '+233 209 090 211',
        helpEmail: 'info@tropigha.com',
        helpDoc:'User Manual_Tropigha Farms Traceability System.pdf',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
        ]
    },
    {
        id: CompanyIds.SVNoble,
        name: 'SV Noble Enterprise',
        logo: 'SVNobelEnt.JPG',
        logoTiny: 'SVNobelEnt.JPG',
        helpLine: '+233 547 245 003',
        helpEmail: 'toshba@ymail.com',
        helpDoc:'User Manual_S. V. Noble Traceability System.pdf',
        accentColor: { primary: '', secondary: ''},
        modules: [
            'MANUFACTURING',
            'OUT-GROWERS',
        ]
    },
]

// Utility function to find a company by its ID
export const getCompanyById = (id) => {
    return companySetup.find(company => company.id === id);
};

// Utility function to check if a string exists in the module array (case-insensitive)
export const moduleExists = (moduleArray, searchString) => {
    return moduleArray.some(module => module.toLowerCase().includes(searchString.toLowerCase()));
};

export const getRunningCompany = () => {
    return getCompanyById(runningCompanyId);
};

export const runningCompany = getRunningCompany();
export const runningCompanyName = runningCompany ? runningCompany.name : 'Default Company Name'; // Use a fallback name


export const runningCompanyHasModule = (moduleToSearch) => {
    return moduleExists(runningCompany.modules, moduleToSearch);
}
