import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { getAdminDashQuickStats } from "../../api/Dashboard/getAdminDashQuickStats";
import { toast } from "react-toastify";

const AdminDashQuickStats = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [adminDashQuickStats, setAdminDashQuickStats] = useState(null);
    const [loadingData, setLoadingData] = useState(false);

    const fetchQuickStats = async () => {
        if (!(user && user.user.token)) {
            navigate("/login");
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }

        setLoadingData(true);
        try {
            const data = await getAdminDashQuickStats(user.user.token);
            setAdminDashQuickStats(data);
        } catch (error) {
            console.error("Error fetching quick stats:", error);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        // Initial data fetch
        fetchQuickStats();

        // Refresh data every 5 minutes
        const intervalId = setInterval(fetchQuickStats, 300000); // 300,000 ms = 5 minutes

        // Cleanup on component unmount
        return () => clearInterval(intervalId);
    }, [user]);

    return (
        <div className="card" style={{ minHeight: "357px" }}>
            <div className="card-header">
                <div className="card-title">Quick Stats</div>
            </div>
            <div className="card-body">
                {loadingData ? (
                    <div>
                        <em>Loading...</em>
                    </div>
                ) : (
                    adminDashQuickStats && (
                        <div className="customScroll5">
                            <div className="quick-analytics">
                                <Link to="/contracts">
                                    <i className="icon-shopping-cart1"></i>{" "}
                                    {adminDashQuickStats?.numberOfNewOrders ?? "--"} New Order
                                    {adminDashQuickStats?.numberOfNewOrders > 1 ? "s" : ""}
                                </Link>
                                <Link to="#">
                                    <i className="icon-package"></i>{" "}
                                    {adminDashQuickStats?.numberOfFields ?? "--"} Field
                                    {adminDashQuickStats?.numberOfFields > 1 ? "s" : ""}
                                </Link>
                                <Link to="#">
                                    <i className="icon-toys"></i>{" "}
                                    {adminDashQuickStats?.numberOfCrops ?? "--"} Crop
                                    {adminDashQuickStats?.numberOfCrops > 1 ? "s" : ""}
                                </Link>
                                <Link to="#">
                                    <i className="icon-globe"></i>{" "}
                                    {adminDashQuickStats?.numberOfPlantings ?? "--"} Planting Record
                                    {adminDashQuickStats?.numberOfPlantings > 1 ? "s" : ""}
                                </Link>
                                <Link to="#">
                                    <i className="icon-sun2"></i>{" "}
                                    {adminDashQuickStats?.numberOfHarvests ?? "--"} Harvest
                                    {adminDashQuickStats?.numberOfHarvests > 1 ? "s" : ""}
                                </Link>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default AdminDashQuickStats;
